/* General */

.media-card {
  overflow: hidden;
  position: relative;
  margin: spacing(4, 0, 2);
  height: 250px;
  @include breakpoints-up('lg') {
    height: 340px;
  }
  figure {
    margin: 0;
    img {
      min-height: 100%;
      width: 100%;
    }
  }
  .badge {
    position: absolute;
    @include right(10px);
    top: 10px;
    color: white;
    font-size: 12px;
    border-radius: 10px;
  }
  .play-btn.btn {
    position: absolute;
    width: 80px;
    height: 80px;
    line-height: 120px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    @include palette-background-paper;
    span {
      &:before {
        font-size: 60px;
        margin-left: $spacing1;
        @include use-theme(color, $palette-secondary-main, $palette-secondary-light)
      }
    }
  }
  .property {
    transition: all 0.3s ease-out;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 170px;
    padding: $spacing3;
    top: 0;
    @include text-align(left);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include left(0);
    color: $palette-common-white;
    background: linear-gradient(to bottom, rgba(238, 238, 238, 0) 20%, #000 90%);
  }
  .media-title {
    color: $palette-common-white;
    font-weight: $font-medium;
    font-size: 20px;
    line-height: 24px;
  }
  /* Orientation */
  &.landscape {
    .media-title {
      @include text-align(left);
    }
  }
  &.portrait {
    max-width: 400px;
    .media-title {
      text-align: center;
      justify-content: center;
    }
  }
  /* Type */
  &.photo {
    cursor: pointer;
  }
  .cover-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
  }
}

