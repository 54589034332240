/*
** RTL Mixins including margin, pading, position, border and border radius
** See documentation https://www.npmjs.com/package/rtl-sass
*/
@import './vendors/rtl-sass/rtl';

/*
** Variables, Functions and Mixins
*/

// Custom variable
@import './utils/variables';

// Custom mixins
@import './utils/mixins';

// Custom functions
@import './utils/functions';

// Custom media queries
@import './utils/media-queries';

// Commons scss class
@import './utils/common';

// Page transition
@import './utils/transition';

// Image API variable
@import './utils/url-img';

// Materialize and Bootstrap overide
@import './utils/root';

// Materialize extended style
@import './utils/material_extends';

// Bootstrap custom media query for grid
@import './utils/grid';

// Bootstrap additional spacing
@import './utils/spacing';

/*
** Components and Pages
*/

// General components
@import './components/logo';
@import './components/title';

// Header and Mobile Nav
@import './components/header';
@import './components/side-nav';

// Homepage
@import './components/home/banner-slider';
@import './components/home/benefit';
@import './components/home/feature';
@import './components/home/integration';
@import './components/home/map';
@import './components/home/promotion';
@import './components/home/testimonials';
@import './components/chat';
@import './components/page-nav';
@import './components/notification';

// Cards components
@import './components/cards/post-card';
@import './components/cards/news-card';
@import './components/cards/pricing-card';
@import './components/cards/testi-card';
@import './components/cards/profile-card';
@import './components/cards/product-card';
@import './components/cards/pricing-card';
@import './components/cards/media-card';
@import './components/cards/case-card';

// Footer components
@import './components/counter-single';
@import './components/footer/extend';
@import './components/footer/basic';
@import './components/footer/contact';
@import './components/footer/sitemap';
@import './components/footer/blog';

// Inner components
@import './components/about';
@import './components/counter';
@import './components/banner-basic';
@import './components/blog';
@import './components/comments';
@import './components/company-logo';
@import './components/error';
@import './components/filter';
@import './components/faq';
@import './components/forms';
@import './components/list';
@import './components/map';
@import './components/paper';
@import './components/pricing';
@import './components/decoration';
@import './components/hero-banner';

// Inner pages
@import './pages/pages';
