.footer-contact {
  position: relative;
  padding-top: $spacing10;
  padding-bottom: $spacing10;
  .container {
    position: relative;
    z-index: 10;
    p {
      font-size: 14px;
    }
  }
  .form-box {
    padding: $spacing5;
    @include breakpoints-down(xs) {
      padding: spacing(3, 2, 1)
    }
    h3 {
      @include use-theme(color, $palette-primary-dark,  $palette-primary-light);
    }
    textarea {
      height: auto
    }
  }
  .logo {
    @include palette-text-secondary;
    text-align: center;
    display: block;
    font-size: 26px;
    font-weight: $font-bold;
    margin: spacing(5, 0);
    img {
      width: 54px;
      height: 54px;
      margin-bottom: $spacing2
    }
    h4 {
      font-size: 26px;
      font-weight: $font-bold;
    }
  }
  .margin {
    margin: $spacing2
  }
  .socmed {
    margin: spacing(3, 0);
    a.btn-icon {
      margin: $spacing1;
      width: 36px;
      height: 36px;
      padding: 0;
      @include use-theme(background, $light-divider, $dark-divider);
      i {
        @include use-theme(color, $palette-primary-main,  $palette-primary-light);
      }
    }
    i {
      font-size: 24px
    }
  }
  .contact {
    @include palette-text-secondary;
  }
  .divider {
    margin: 12px;
    border: none;
    background: none
  }
}