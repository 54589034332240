#benefit {
  .root {
    color: #FFF;
    text-align: center;
    background-color: $palette-primary-dark;
  }

  .illustration-bg {
    position: absolute;
    left: 0;
    top: $spacing5;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }

  .wrapper {
    position: relative;
    padding-top: $spacing10;
    margin: 0 auto;
  }

  .carousel {
    margin-top: $spacing5;
    z-index: 1;
    figure {
      border-radius: 10px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    .slider {
      @include breakpoints-down(sm) {
        height: 300px;
      }
      @include breakpoints-down(xs) {
        height: 40px;
      }
    }
    .slick-dots{
      position: relative;
      li {
        width: 25px;
        height: 25px;
        button:before {
          border-radius: 4px;
          width: 12px;
          height: 12px;
          background: $palette-secondary-light;
          opacity: 1;
          border: none;
        }
        &.slick-active button:before {
          top: -6px;
          left: -4px;
          width: 22px;
          height: 22px;
          border: 4px solid $palette-secondary-light;
          background: linear-gradient($palette-secondary-light, $palette-secondary-main);
        }
      }
    }
  }

  .nav {
    position: absolute;
    top: 50%;
  }

  .prev, .next {
    position: absolute;
    z-index: 5;
    top: 280px;
    transform: translateY(-50%);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    @include breakpoints-down(sm) {
      display: none;
    }
    i {
      color: #fff;
      font-size: 100px;
    }
  }

  /* Position the "next button" to the right */
  .nav {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }

  .next {
    right: -80px;
    &:hover {
      right: -85px;
    }
  }

  .prev {
    left: -80px;
    &:hover {
      left: -85px;
    }
  }

  .slide {
    @include breakpoints-up(sm) {
      padding: $spacing2;
    }
  }

  .border-gradient {
    @include shadow8;
    border-radius: 10px;
    padding: $spacing1;
    @include use-theme(background, linear-gradient(180deg, $palette-primary-light 20%, $palette-primary-main 70%), linear-gradient(180deg, $palette-primary-main 20%, $palette-primary-dark 70%));
  }

  .desc {
    padding: spacing(0, 0, 8, 3);
    @include breakpoints-down(xs) {
      padding: spacing(0, 0, 8, 0);
      button {
        width: 100%
      }
    }
  }

  .img {
    margin: spacing(3, 0, 0, 0);
    overflow: hidden;
    @include breakpoints-down(md) {
      @include text-align(right);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%
    }
    img {
      max-width: 100%;
      display: block;
      @include breakpoints-down(sm) {
        width: 100%
      }
    }
  }
}