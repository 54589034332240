.use-text {
  &-title {
    font-weight: $font-bold;
    font-size: 48px;
    line-height: 72px;
    @include breakpoints-down(md) {
      font-size: 38px;
      line-height: 60px;
    }
    @include breakpoints-down(xs) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  &-title2 {
    font-size: 36px;
    line-height: 56px;
    font-weight: $font-bold;
    @include breakpoints-down(md) {
      font-size: 32px;
      line-height: 48px;
    }
    @include breakpoints-down(xs) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  &-subtitle {
    font-weight: $font-medium;
    font-size: 28px;
    line-height: 44px;
    @include breakpoints-down(md) {
      font-size: 24px;
      line-height: 36px;
    }
    @include breakpoints-down(xs) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  &-subtitle2 {
    font-weight: $font-regular;
    font-size: 22px;
    line-height: 32px;
    @include breakpoints-down(md) {
      font-size: 20px;
      line-height: 32px
    }
    @include breakpoints-down(xs) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-paragraph {
    font-weight: $font-regular;
    font-size: 16px;
    line-height: 24px
  }
  &-caption {
    font-weight: $font-regular;
    font-size: 16px;
    line-height: 24px;
    @include breakpoints-down(xs) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &-primary {
    .theme--dark & {
      color: $palette-primary-light
    }
    .theme--light & {
      color: $palette-primary-dark
    }
  }
  &-secondary {
    .theme--dark & {
      color: $palette-secondary-light
    }
    .theme--light & {
      color: $palette-secondary-dark
    }
  }
  &-bold {
    font-weight: $font-bold !important;
  }
  &-medium {
    font-weight: $font-medium !important;
  }
  &-regular {
    font-weight: $font-regular !important;
  }
  &-primary-color {
    color: $palette-primary-main;
  }
  &-secondary-color {
    color: $palette-secondary-main;
  }
  &-accent-color {
    color: $palette-accent-main;
  }
}

// Hidden class media query
.hidden {
  &-lg-down {
    @include breakpoints-down(lg) {
      display: none !important;
    }
  }
  &-md-down {
    @include breakpoints-down(md) {
      display: none !important;
    }
  }
  &-sm-down {
    @include breakpoints-down(sm) {
      display: none !important;
    }
  }
  &-xs-down {
    @include breakpoints-down(xs) {
      display: none !important;
    }
  }
  &-lg-up {
    @include breakpoints-up(lg) {
      display: none !important;
    }
  }
  &-md-up {
    @include breakpoints-up(md) {
      display: none !important;
    }
  }
  &-sm-up {
    @include breakpoints-up(sm) {
      display: none !important;
    }
  }
}

//Show class media query
.show {
  &-lg-down {
    @include breakpoints-up(xl) {
      display: none !important;
    }
  }
  &-md-down {
    @include breakpoints-up(lg) {
      display: none !important;
    }
  }
  &-sm-down {
    @include breakpoints-up(md) {
      display: none !important;
    }
  }
  &-xs-down {
    @include breakpoints-up(sm) {
      display: none !important;
    }
  }
  &-xl-up {
    @include breakpoints-down(lg) {
      display: none !important;
    }
  }
  &-lg-up {
    @include breakpoints-down(md) {
      display: none !important;
    }
  }
  &-md-up {
    @include breakpoints-down(sm) {
      display: none !important;
    }
  }
  &-sm-up {
    @include breakpoints-down(xs) {
      display: none !important;
    }
  }
}