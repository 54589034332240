$building-deco: '../images/cloud/building_deco_footer.png';

.footer-counter {
  position: relative;
  @include use-theme(background, $palette-primary-light, $palette-primary-dark);
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include use-theme(background, $palette-common-white, $dark-background-default);
    opacity: 0.8;
  }
  &.extend {
    margin-top: $spacing10 * 2;
    padding-top: $spacing1;
    padding-bottom: $spacing10;
    @include breakpoints-up(sm) {
      padding-bottom: $spacing8;
    }
    &:after {
      background: url($building-deco) repeat-x bottom center;
      background-size: 50%;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 150px;
      @include use-theme(opacity, 1, 0.3);
    }
  }
  .round {
    content: "";
    overflow: hidden;
    @include use-theme(background, $palette-primary-light, $palette-primary-dark);
    position: absolute;
    left: -10%;
    border-radius: 50%;
    top: -80px;
    width: 120%;
    height: 350px;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include use-theme(background, $palette-common-white, $dark-background-default);
      opacity: 0.8;
    }
    @include breakpoints-down(xs) {
      height: 120px;
    }
  }

  .action {
    text-align: center;
    padding: spacing(0, 2);
    position: relative;
    h4 {
      font-weight: $font-medium;
      margin-bottom: $spacing3;
      @include breakpoints-down(xs) {
        font-size: 28px;
        line-height: 44px
      }
    }
  }

  .building-deco-footer {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    @include use-theme(filter, brightness(1), brightness(0.5));
    img {
      height: 120px;
    }
  }
}

