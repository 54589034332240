#map-locations {
  .root {
    position: relative;
    z-index: 10
  }
  .location-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    .location-item {
      position: absolute;
      .btn-icon {
        background: transparent;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .text {
    @include breakpoints-down(xs) {
      margin-top: $spacing5
    }
    h3 {
      font-weight: $font-bold;
      span {
        width: 20px;
        height: 20px;
        display: inline-block;
        @include margin-right($spacing1);
        border-radius: 3px;
      }
    }
    p {
      margin-bottom: $spacing3;
      font-size: 18px;
      line-height: 32px;
      @include breakpoints-down(xs) {
        margin-bottom: $spacing6
      }
    }
    @include breakpoints-up(sm) {
      padding-top: $spacing5;
    }
    @include breakpoints-down(sm) {
      text-align: center
    }
  }

  .title-primary {
    color: $palette-primary-main;
    span {
      background: linear-gradient($palette-primary-light, $palette-primary-main);
    }
  }

  .title-secondary {
    color: $palette-secondary-main;
    span {
      background: linear-gradient($palette-secondary-light, $palette-secondary-main);
    }
  }

  .map-wrap {
    @include breakpoints-down(xs) {
      overflow: auto;
    }
  }

  .world-map {
    background: url(../images/cloud/worldMap.svg) no-repeat center center;
    background-size: 100%;
    position: relative;
    margin: 0 auto;
    height: 360px;
    width: 580px;
  }
  .location {
    position: absolute
  }
  .avatar {
    transition: all 0.3s ease-out;
    &:hover {
      transform: scale(1.2)
    }
  }
  .dot {
    border-radius: 3px;
    display: block;
    width: 12px;
    height: 12px;
    transition: all 0.3s ease-out;
    &.primary-color {
      background: linear-gradient($palette-primary-light, $palette-primary-main);
    }
    &.secondary-color {
      background: linear-gradient($palette-secondary-light, $palette-secondary-main);
    }
  }
  .title-main {
    @include palette-text-secondary;
  }
  .popover {
    pointer-events: none
  }
}