$building-deco: '../images/cloud/building_deco_footer.png';

.form-deco, .page-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.cloud-deco {
  .cloud {
    @include use-theme(opacity, 0.25, 0.12);
    @include use-theme(fill, $palette-primary-light, $palette-primary-main);
    width: 560px;
    height: 280px;
  }
  .big, .small {
    position: absolute;
    opacity: 0.2
  }

  .small {
    transform: scale(0.4);
  }

  .big {
    transform: scale(0.7);
  }

  .left-deco, .right-deco {
    position: absolute;
  }
  .left-deco {
    left: -360px;
    top: -250px;
    .big {
      left: 80px;
      top: 200px;
    }
    .small {
      left: 200px;
      top: 280px;
    }
  }

  .right-deco {
    right: -320px;
    top: -120px;
    .big {
      right: 50px;
      top: 190px;
    }
    .small {
      right: 0px;
      top: 240px;
    }
  }
}

.building-deco {
  position: absolute;
  bottom: $spacing5 * -1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  @include use-theme(filter, brightness(1), brightness(0.5));
  img {
    height: 120px;
    position: relative;
    z-index: 2;
  }
  &:after {
    background: url($building-deco) repeat-x bottom center;
    background-size: 50%;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 150px;
    @include use-theme(opacity, 1, 0.3);
  }
}
