/* General */

.profile-card {
  display: flex;
  border-radius: $rounded-medium;
  .btn.main-btn {
    @include breakpoints-up(sm) {
      min-width: 150px;
    }
  }
  .responsive-img {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .properties {
    .btn.btn-flat {
      padding: 0 $spacing1;
      border-radius: $rounded-small;
    }
  }
  .figure {
    display: block;
  }
  .avatar {
    margin: 0 auto;
  }
  .subtitle {
    @include palette-text-secondary;
    font-size: 18px;
  }
  .desc {
    margin-top: $spacing2;
    padding: spacing(0, 2, 2)
  }
  .action {
    padding: 0;
    display: flex;
    .btn {
      padding: $spacing1;
      height: auto;
      .btn-content {
        flex-direction: column;
        text-transform: none;
        font-weight: 400;
        display: flex;
        font-size: 12px
      }
    }
    .icon {
      color: $palette-secondary-main
    }
  }
  /* Orientation */
  &.portrait {
    max-width: 400px;
    flex-direction: column;
    text-align: center;
    .properties {
      padding-left: $spacing2;
      padding-right: $spacing2;
    }
    .avatar {
      margin-top: -50px;
    }
    .action {
      border-top: 1px solid;
      @include palette-divider;
      padding: spacing(2, 0);
      margin-top: $spacing2;
      .btn {
        flex: 1;
      }
    }
  }
  &.landscape {
    max-width: 820px;
    flex-direction: row;
    align-items: center;
    @include text-align(left);
    height: 140px;
    .figure {
      width: 100px;
      height: 100%;
      @include margin-right($spacing4 * -1);
      .img {
        max-height: 100%
      }
    }
    .subtitle {
      font-size: 14px;
    }
    .properties {
      flex: 1;
      display: flex;
      align-items: center;
      padding: $spacing2;
      .card-content {
        padding: 0;
        h5 {
          margin: 0
        }
      }
      @include breakpoints-down(xs) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .desc {
      padding: 0;
      @include breakpoints-down(sm) {
        margin-top: $spacing1;
        margin-bottom: $spacing1;
      }
    }
    .action {
      & > * {
        margin: 0 4px;
      }
      @include breakpoints-down(xs) {
        display: none
      }
    }
    .btn.main-btn {
      width: 100%
    }
    .action-area {
      @include breakpoints-up(sm) {
        @include margin-left($spacing4);  
      }
    }
  }
  /* Type */
  &.full { 
    overflow: hidden;
    position: relative;
    .figure {
      height: 160px
    }
  }
  &.round {
    .figure {
      margin: $spacing1;
      overflow: hidden;
      height: 125px;
      border-radius: $rounded-medium
    }
    &.landscape {
      @include breakpoints-down(sm) {
        .figure {
          width: 100px;
          height: 90%;
        }
      }
    }
  }
  &.oval {
    overflow: hidden;
    &.portrait {
      .figure {
        height: 150px;
        width: 120%;
        @include margin-left(-10%);
        overflow: hidden;
        border-radius: 0 0 50% 50%;
        & > div {
          overflow: visible;
        }
      }
    }
    &.landscape {
      .figure {
        overflow: hidden;
        & > div {
          border-radius: 0 50% 50% 0;
          @include use-rtl(border-radius, 50% 0 0 50%);
          height: 120% !important;
          margin-top: -10%;
        }
      }
    }
  }
  &.over {
    overflow: visible;
    &.portrait {
      margin-top: $spacing2;
      .figure {
        height: 200px;
        overflow: hidden;
        @include shade;
        margin: spacing(-2, 2, 0);
        border-radius: $rounded-medium;
      }
    }
    &.landscape {
      @include margin-left($spacing2);
      .figure {
        overflow: hidden;
        @include shade;
        height: 105px;
        @include margin-left($spacing2 * -1);
        border-radius: $rounded-medium;
      }
    }
  }
}

